exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-topics-js": () => import("./../../../src/pages/topics.js" /* webpackChunkName: "component---src-pages-topics-js" */),
  "component---src-pages-ui-index-js": () => import("./../../../src/pages/ui/index.js" /* webpackChunkName: "component---src-pages-ui-index-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-category-ui-js": () => import("./../../../src/templates/category-ui.js" /* webpackChunkName: "component---src-templates-category-ui-js" */),
  "component---src-templates-list-product-js": () => import("./../../../src/templates/list-product.js" /* webpackChunkName: "component---src-templates-list-product-js" */),
  "component---src-templates-post-blog-js": () => import("./../../../src/templates/post-blog.js" /* webpackChunkName: "component---src-templates-post-blog-js" */),
  "component---src-templates-post-doc-js": () => import("./../../../src/templates/post-doc.js" /* webpackChunkName: "component---src-templates-post-doc-js" */),
  "component---src-templates-post-page-js": () => import("./../../../src/templates/post-page.js" /* webpackChunkName: "component---src-templates-post-page-js" */),
  "component---src-templates-post-product-js": () => import("./../../../src/templates/post-product.js" /* webpackChunkName: "component---src-templates-post-product-js" */),
  "component---src-templates-post-ui-js": () => import("./../../../src/templates/post-ui.js" /* webpackChunkName: "component---src-templates-post-ui-js" */)
}

